import shopSetting from '@/utils/shopSetting'

const DashBoard = () => import('../pages/user/Dashboard')
const PurchaseHistory = () => import('../pages/user/PurchaseHistory')
const OrderDetails = () => import('../pages/user/OrderDetails')
const RefundRequests = () =>
  import('../pages/user/refundRequest/RefundRequestList')
const RefundRequestCreate = () =>
  import('../pages/user/refundRequest/RefundRequestCreate')
const Wishlist = () => import('../pages/user/Wishlist')
const Conversations = () => import('../pages/user/Conversations')
const ConversationDetails = () => import('../pages/user/ConversationDetails')
const FollowedShops = () => import('../pages/user/FollowedShops')
const Wallet = () => import('../pages/user/Wallet')
const Coupon = () => import('../pages/user/Coupon')
const Profile = () => import('../pages/user/Profile')
const Focus = () => import('../pages/user/Focus')
const UserLayout = () => import('../components/user/UserLayout')
const Error404 = () => import('../pages/errors/404')

window.shopSetting = shopSetting

export default [
  {
    path: '/user/',
    component: UserLayout,
    redirect: '/user/dashboard',
    children: [
      {
        path: 'dashboard',
        component: DashBoard,
        name: 'DashBoard',
        meta: { requiresAuth: true }
      },
      {
        path: 'purchase-history',
        component: PurchaseHistory,
        name: 'PurchaseHistory',
        meta: { requiresAuth: true }
      },
      {
        path: 'purchase-history/:code',
        component: OrderDetails,
        name: 'OrderDetails',
        meta: { requiresAuth: true }
      },
      {
        path: 'refund-requests',
        component: RefundRequests,
        name: 'RefundRequests',
        meta: { requiresAuth: true }
      },
      {
        path: 'create-refund-request/:orderId',
        component: RefundRequestCreate,
        name: 'CreateRefundRequest',
        meta: { requiresAuth: true }
      },
      {
        path: 'wishlist',
        component: Wishlist,
        name: 'Wishlist',
        meta: { requiresAuth: true }
      },
      {
        path: 'followed-shops',
        component: FollowedShops,
        name: 'FollowedShops',
        meta: { requiresAuth: true }
      },

      {
        path: 'product-query',
        component: Conversations,
        name: 'Conversations',
        meta: { requiresAuth: true }
      },
      {
        path: 'product-query/:slug',
        component: ConversationDetails,
        name: 'ConversationsDetails',
        meta: { requiresAuth: true }
      },
      {
        path: 'wallet',
        component: Wallet,
        name: 'Wallet',
        meta: { requiresAuth: true }
      },
      {
        path: 'coupon',
        component: Coupon,
        name: 'Coupon',
        meta: { requiresAuth: true }
      },
      {
        path: 'profile',
        component: Profile,
        name: 'Profile',
        meta: { requiresAuth: true }
      },

      {
        path: 'focus',
        component: Focus,
        name: 'Focus',
        meta: { requiresAuth: true }
      }
    ]
  }
]
