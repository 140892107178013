export default {
  namespaced: true,

  state: {
    headerSwitch: true,
    promiseSwitch: true,
    footerSwitch: true,
    sidebarCartSwitch: true,
    addToCartDialogSwitch: true,
    mobileMenuSwitch: true
  },

  getters: {
    getHeaderSwitch(state) {
      return state.headerSwitch
    },

    getPromiseSwitch(state) {
      return state.promiseSwitch
    },

    getFooterSwitch(state) {
      return state.footerSwitch
    },

    getSidebarCartSwitch(state) {
      return state.sidebarCartSwitch
    },

    getAddToCartDialogSwitch(state) {
      return state.addToCartDialogSwitch
    },

    getMobileMenuSwitch(state) {
      return state.mobileMenuSwitch
    }
  },

  mutations: {
    closeOtherComponent(state) {
      state.headerSwitch = false
      state.promiseSwitch = false
      state.footerSwitch = false
      state.sidebarCartSwitch = false
      state.addToCartDialogSwitch = false
      state.mobileMenuSwitch = false
    },

    openOtherComponent(state) {
      state.headerSwitch = true
      state.promiseSwitch = true
      state.footerSwitch = true
      state.sidebarCartSwitch = true
      state.addToCartDialogSwitch = true
      state.mobileMenuSwitch = true
    },
  }
}