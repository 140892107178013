
const AllShops = () => import('../pages/shop/AllShops')
const ShopRegistration = () => import('../pages/shop/ShopRegisterIndex')
const ShopInformation = () => import('../pages/shop/ShopInformation')
const ShopPersonalInformation = () => import('../pages/shop/ShopPersonalInformation')
const ShopConfirmation = () => import('../pages/shop/ShopConfirmation')
const ShopRegisterEnd = () => import('../pages/shop/ShopRegisterEnd')
const ShopLayout = () => import('../pages/shop/ShopLayout')
const ShopDetails = () => import('../pages/shop/ShopDetails')
const ShopCoupons = () => import('../pages/shop/ShopCoupons')
const ShopProducts = () => import('../pages/shop/ShopProducts')

export default [
    {
        path: '/all-shops',
        component: AllShops,
        name: 'AllShops',
        meta: { requiresAuth: false },
    },
    {
        path: '/shop-information',
        component: ShopInformation,
        name: 'ShopInformation',
        meta: { requiresAuth: false },
    },
    {
        path: '/shop-register-end',
        component: ShopRegisterEnd,
        name: 'ShopRegisterEnd',
        meta: { requiresAuth: false },
    },
    {
        path: '/shop-personal-information',
        component: ShopPersonalInformation,
        name: 'ShopPersonalInformation',
        meta: { requiresAuth: false },
    },
    {
        path: '/register-shop',
        component: ShopRegistration,
        name: 'ShopRegistration',
        meta: { requiresAuth: false },
    },
    {
        path: '/register-shop-confirmation',
        component: ShopConfirmation,
        name: 'ShopConfirmation',
        meta: { requiresAuth: false },
    },
    {
        path: '/shop/',
        component: ShopLayout,
        children: [
            {
                path: ':slug',
                component: ShopDetails ,
                name: 'ShopDetails',
                meta: { requiresAuth: false },
            },
            {
                path: ':slug/coupons',
                component: ShopCoupons ,
                name: 'ShopCoupons',
                meta: { requiresAuth: false },
            },
            {
                path: ':slug/products/:categorySlug?',
                component: ShopProducts ,
                name: 'ShopProducts',
                meta: { requiresAuth: false },
            },
        ]
    }

]