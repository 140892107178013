<!-- eslint-disable vue/html-indent -->
<template>
  <div class="topbar">
    <div v-if="topBannerVisible && !loading && data.top_banner.img" class="position-relative">
      <dynamic-link :to="data.top_banner.link" append-class="text-reset d-block lh-0">
        <img :src="addResourceImage(data.top_banner.img)" class="img-fit h-50px w-100" />
      </dynamic-link>
      <v-btn
        elevation="0"
        fab
        outlined
        x-small
        class="absolute-top-right rounded border-2 btn-xxs mt-2 me-2"
        color="white"
        @click.native="closeTopBanner"
      >
        <i class="las la-times ts-10" />
      </v-btn>
    </div>
    <v-container class="fs-13 py-0 px-0 px-md-3">
      <v-row align="center" class="my-0 d-none d-md-flex">
        <v-col cols="6" class="py-2">
          <div class="d-flex align-center">
            <!-- language switcher -->
            <v-menu
              v-if="data.show_language_switcher == 'on' && allLanguages.length > 1"
              offset-y
              :close-on-click="menuCloseOnClick"
              :elevation="2"
              z-index="99999"
            >
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" class="d-flex align-center" v-on="on">
                  <span class="opacity-60">{{ userLanguageObj.name }}</span>
                  <i class="las la-angle-down ms-1 fs-12" />
                </span>
              </template>

              <v-list class="fs-13">
                <v-list-item
                  v-for="(language, i) in allLanguages"
                  :key="i"
                  class="c-pointer d-flex align-center"
                  @click="switchLanguage(language.code)"
                >
                  <img
                    :src="require(`@/assets/img/flags/${language.flag}.png`)"
                    class="me-1 h-10px"
                  />
                  <v-list-item-title class="fs-13 opacity-60">
                    {{ language.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- currency switcher -->
            <!-- <v-menu offset-y :close-on-click="menuCloseOnClick">
              <template #activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  class="d-flex align-center py-1 ms-2"
                  v-on="on"
                >
                  <span class="opacity-60">
                    {{ cselectedCurrency.name }} ({{
                      cselectedCurrency.sysmbol
                    }})
                  </span>
                  <i class="las la-angle-down ms-1 fs-12"></i>
                </span>
              </template>

              <v-list>
                <v-list-item
                  v-for="(currency, i) in allCurrencies"
                  :key="i"
                  class="c-pointer"
                >
                  <v-list-item-title class="fs-13 opacity-60">
                    {{ currency.name }} ({{ currency.symbol }})
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
            <v-divider
              v-if="data.show_language_switcher == 'on' && allLanguages.length > 1"
              vertical
              class="mx-4"
            />

            <!-- 货币符号 -->
            <v-menu offset-y :close-on-click="menuCloseOnClick" :elevation="2" z-index="99999">
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" class="d-flex align-center" v-on="on">
                  <span class="opacity-60">{{ unit?.name }}</span>
                  <i class="las la-angle-down ms-1 fs-12" />
                </span>
              </template>

              <v-list class="fs-13">
                <v-list-item
                  v-for="(unit, i) in units"
                  :key="i"
                  class="c-pointer d-flex align-center"
                  @click="switchUnit(unit)"
                >
                  <!-- <img
                    :src="require(`@/assets/img/flags/${unit.value}.png`)"
                    class="me-1 h-10px"
                  /> -->
                  <v-list-item-title class="fs-13 opacity-60">
                    {{ unit.name }} - {{ unit.value }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-divider vertical class="mx-4" />

            <a
              :href="data.mobile_app_links?.play_store"
              target="_blank"
              class="me-4 text-reset"
              v-if="data.mobile_app_links && data.mobile_app_links.show_play_store == 'on'"
            >
              <i class="lab la-android" />
              <span class="opacity-60">{{ $t('play_store') }}</span>
            </a>
            <a
              :href="data.mobile_app_links?.app_store"
              target="_blank"
              class="text-reset"
              v-if="data.mobile_app_links && data.mobile_app_links.show_app_store == 'on'"
            >
              <i class="lab la-apple" />
              <span class="opacity-60">{{ $t('app_store') }}</span>
            </a>
            <template v-if="is_addon_activated('multi_vendor')">
              <v-divider
                vertical
                class="mx-4"
                v-if="
                  data.mobile_app_links &&
                  (data.mobile_app_links.show_play_store == 'on' ||
                    data.mobile_app_links.show_app_store == 'on')
                "
              />
              <router-link :to="{ name: 'ShopRegistration' }" class="text-reset opacity-60">
                {{ $t('be_a_seller') }}
              </router-link>
            </template>
          </div>
        </v-col>

        <v-col cols="6" class="py-2">
          <div class="d-flex align-center justify-end">
            <!-- <router-link
              :to="{ name: 'TrackOrder' }"
              class="text-reset opacity-60"
            >
              {{ $t('track_order') }}
            </router-link>
            <v-divider vertical class="mx-4" /> -->
            <a :href="contactUrl" target="_blank" class="text-reset opacity-60">
              <!-- <i class="la la-phone" /> -->
              <span>{{ $t('help_line_link') }}</span>
              <!-- <span>{{ data.helpline }}</span> -->
            </a>
            <v-divider vertical class="mx-4" />
            <!-- <router-link
                            :to="{ name: 'Home' }"
                            class="text-reset opacity-60 me-3"
                        >
                            <span class="">Compare (0)</span>
                        </router-link> -->
            <router-link :to="{ name: 'Wishlist' }" class="text-reset opacity-60">
              <span class="">{{ $t('wishlist') }} ({{ getTotalWishlisted }})</span>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  props: {
    loading: { type: Boolean, required: true, default: true },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    topBannerVisible: false,
    // topBanner: {
    //   image: require("@/uploads/img/topbar.jpg"),
    //   link: "",
    // },
    currencies: [
      {
        name: 'U.S. Dollar',
        sysmbol: '$',
        code: 'USD'
      },
      {
        name: 'Taka',
        sysmbol: 'Tk',
        code: 'BDT'
      }
    ],
    // cselectedCurrency: {
    //   name: 'U.S. Dollar',
    //   sysmbol: '$',
    //   code: 'USD'
    // },
    menuCloseOnClick: true
  }),
  computed: {
    ...mapGetters('wishlist', ['getTotalWishlisted']),
    ...mapGetters('app', ['userLanguageObj', 'allLanguages', 'allCurrencies', 'unit', 'units', 'contactUrl'])
  },
  methods: {
    ...mapActions('app', ['fetchProductQuerries', 'fetchProductMessage', 'fetchUnits', 'setCurrentUnit']),
    ...mapActions('wishlist', ['fetchWislistProducts']),
    ...mapActions('app', ['setLanguage']),
    ...mapMutations('app', ['setUnit', 'setGeneralSettingsCurrency']),

    switchLanguage(locale) {
      if (this.$i18n.locale !== locale) {
        this.setLanguage(locale)
        window.location.reload()
      }
    },

    async switchUnit(unit) {
      if (this.unit.code !== unit.code) {
        await this.setCurrentUnit(unit)
        window.setTimeout(() => {
          window.location.reload()
        }, 300)
      }
    },

    closeTopBanner() {
      this.topBannerVisible = false
      this.setSession('shopTopBanner', 'hidden')
    }
  },
  created() {
    if (this.checkSession('shopTopBanner') != 'hidden') {
      this.topBannerVisible = true
    }
    this.fetchUnits()
    this.fetchWislistProducts()
    this.fetchProductQuerries()
    this.fetchProductMessage()
    setInterval(() => {
      // this.fetchProductQuerries()
      this.fetchProductMessage()
    }, 8000)
  }
}
</script>
<style scoped>
.topbar {
  position: relative;
  z-index: 2;
  background-color: #fff;
}
</style>
