const publicPath = '@'
const asset = (path = '') => publicPath + path
const imagePlaceholder = (size = 'square') =>
  size == 'square'
    ? require('@/assets/img/placeholder.jpg')
    : require('@/assets/img/placeholder-rect.jpg')

export default {
  publicPath,
  asset,
  imagePlaceholder,
}
