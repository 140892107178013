import Mixin from './../../utils/mixin'

const state = {
  paymentMethods: []
}

const mutations = {
  setPaymentMethods(state, paymentMethods) {
    state.paymentMethods = paymentMethods
  }
}

const actions = {
  /**
   * 获取充值方式列表
   */
  async fetchRechargeMethods({ commit }) {
    try {
      const res = await Mixin.methods.call_api('get', 'user/wallet/recharge-list')

      commit('setPaymentMethods', res?.data?.data || [])
    } catch (e) {
      console.log('fetch payment methods list: ', e)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
