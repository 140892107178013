const Login = () => import('../pages/auth/Login')
const Registration = () => import('../pages/auth/Registration')
const ForgotPassword = () => import('../pages/auth/ForgotPassword')
const NewPassword = () => import('../pages/auth/NewPassword')
const VerifyAccount = () => import('../pages/auth/VerifyAccount')
const UpdateAccount = () => import('../pages/auth/UpdateAccount')

export default [
    {
        path: '/user/login',
        component: Login,
        name: 'Login',
        meta: { requiresAuth: false },
    },
    {
        path: '/user/registration',
        component: Registration ,
        name: 'Registration',
        meta: { requiresAuth: false },
    },
    {
        path: '/user/forgot-password',
        component: ForgotPassword ,
        name: 'ForgotPassword',
        meta: { requiresAuth: false },
    },
    {
        path: '/user/new-password',
        component: NewPassword ,
        name: 'NewPassword',
        meta: { requiresAuth: false },
    },
    {
        path: '/user/verify-account',
        component: VerifyAccount ,
        name: 'VerifyAccount',
        meta: { requiresAuth: false },
    },
    {
        path: '/user/update-account',
        component: UpdateAccount ,
        name: 'UpdateAccount',
        meta: { requiresAuth: false },
    }
]