import { i18n } from '../../plugins/i18n'
import shopSetting from '@/utils/shopSetting'
import Mixin from '../../utils/mixin'

const loadState = () => ({
  chatWindowOpen: false,
  cartDrawerOpen: false,
  mobileSideMenuOpen: false,
  showLoginDialog: false,
  showAddToCartDialog: false,
  showConversationDialog: false,
  cartDialogProductSlug: null,
  accessToken: localStorage.getItem('shopAccessToken') || null,
  currentUser: {},
  sociaLoginStatus: null,
  authSettings: shopSetting.authSettings,

  // 登录后存储 406 验证邮箱时需要用到
  unVerifyEmail: '',

  // 验证码
  referralCode: '',

  // 店铺名称和
  shopName: '',

  // 店铺手机号码
  shopPhone: '',

  // 店铺地址
  shopAddress: ''
})
export default {
  namespaced: true,
  state: loadState(),
  getters: {
    // 店铺名称
    shopName(state) {
      return state.shopName
    },

    // 店铺号码
    shopPhone(state) {
      return state.shopPhone
    },

    // 店铺地址
    shopAddress(state) {
      return state.shopAddress
    },

    referralCode(state) {
      return state.referralCode
    },

    chatWindowOpen(state) {
      return state.chatWindowOpen
    },
    cartDrawerOpen(state) {
      return state.cartDrawerOpen
    },
    mobileSideMenuOpen(state) {
      return state.mobileSideMenuOpen
    },
    showLoginDialog(state) {
      return state.showLoginDialog
    },
    showAddToCartDialog(state) {
      return state.showAddToCartDialog
    },
    showConversationDialog(state) {
      return state.showConversationDialog
    },
    cartDialogProductSlug(state) {
      return state.cartDialogProductSlug
    },
    isAuthenticated(state) {
      return state.accessToken !== null
    },
    accessToken(state) {
      return state.accessToken
    },
    currentUser(state) {
      return state.currentUser
    },
    sociaLoginStatus(state) {
      return state.sociaLoginStatus
    },
    authSettings(state) {
      return state.authSettings
    },
    getUnverifyEmail(state) {
      return state.unVerifyEmail
    }
  },

  mutations: {
    setShopName(state, payload) {
      state.shopName = payload
    },

    setShopPhone(state, payload) {
      state.shopPhone = payload
    },

    setShopAddress(state, payload) {
      state.shopAddress = payload
    },

    setReferralCode(state, payload) {
      state.referralCode = payload
    },

    updateChatWindow(state, status) {
      state.chatWindowOpen = status
    },
    updateCartDrawer(state, status) {
      state.cartDrawerOpen = status
    },
    updateMobileSideMenu(state, status) {
      state.mobileSideMenuOpen = status
    },
    showLoginDialog(state, status) {
      state.showLoginDialog = status
    },
    showAddToCartDialog(state, { status, slug }) {
      state.showAddToCartDialog = status
      state.cartDialogProductSlug = slug
    },
    showConversationDialog(state, { status }) {
      state.showConversationDialog = status
    },
    login(state, data) {
      state.accessToken = data.access_token
      state.currentUser = data.user

      localStorage.setItem('shopAccessToken', data.access_token)
    },
    logout(state) {
      localStorage.removeItem('shopAccessToken')
      const newState = loadState()
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key]
      })
    },
    setUser(state, data) {
      state.currentUser = data
    },
    setSociaLoginStatus(state, status) {
      state.sociaLoginStatus = status
    },
    setAccessToken(state, token) {
      state.accessToken = token
      localStorage.setItem('shopAccessToken', token)
    },
    deductFromWallet(state, amount) {
      if (state.currentUser.balance >= amount) {
        state.currentUser.balance -= amount
      }
    },

    setAuthSettingsVerify(state, payload) {
      state.authSettings['customer_login_with'] = payload
      state.authSettings['customer_otp_with'] = payload
    },

    setAuthSettings(state) {
      const isMobile = state.authSettings['customer_login_with'] === 'phone'
      state.authSettings['customer_login_with'] = isMobile ? 'email' : 'phone'
      state.authSettings['customer_otp_with'] = isMobile ? 'email' : 'phone'
    },

    setUnVerifyEmail(state, payload) {
      state.unVerifyEmail = payload
    }
  },
  actions: {
    async getUser({ commit, getters }) {
      if (getters.accessToken) {
        try {
          const res = await Mixin.methods.call_api('get', `user/info`)
          if (res.data.success) {
            commit('setUser', res.data.user)
            commit('follow/setFollowedShops', res.data.followed_shops || [], {
              root: true
            })
          } else {
            commit('logout')
          }
        } catch (e) {
          commit('logout')
        }
      }
    },
    rechargeWallet({ dispatch }, status) {
      if (status && status == 'success') {
        Mixin.methods.snack({
          message: i18n.t('wallet_successfully_recharged')
        })
        dispatch('getUser')
      } else if (status && status == 'failed') {
        Mixin.methods.snack({
          message: i18n.t('wallet_recharge_failed'),
          color: 'red'
        })
      }
    },
    deductFromWallet({ commit }, amount) {
      commit('deductFromWallet', amount)
    },
    checkSocialLoginStatus({ dispatch, getters }) {
      if (getters.sociaLoginStatus == 'success') {
        Mixin.methods.snack({ message: i18n.t('login_successfull') })
        dispatch('getUser')
      } else if (getters.sociaLoginStatus == 'failed') {
        Mixin.methods.snack({
          message: i18n.t('something_went_wrong'),
          color: 'red'
        })
      }
    },
    login({ commit }, data) {
      commit('login', data)
    },
    logout({ commit }) {
      commit('logout')
    },
    showConversationDialog({ commit }, status) {
      if (this.getters['auth/isAuthenticated']) {
        commit('showConversationDialog', status)
      } else {
        commit('auth/showLoginDialog', true, { root: true })
      }
    }
  }
}
